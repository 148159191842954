@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  padding: 96px 0;
  display: flex;
  justify-content: space-between;

  @include mediaMobile {
    flex-direction: column;
    padding: 48px 0;
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: -250px;

  @include mediaTablet {
    width: calc(50% - 16px);
  }

  @include mediaMobile {
    display: none;
  }
}

.columnMobile {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  display: none;

  @include mediaMobile {
    display: block;
  }
}

.img {
  margin-top: 32px;
  transform: scale(1);
  transition: 600ms ease-out;

  &:hover {
    transform: scale(1.03);
    transition: 600ms ease-out;
  }

  @include mediaMobile {
    margin-top: 16px;
  }
}

.greenCard {
  background: var(--secondary) !important;

  h1 {
    color: var(--white) !important;
  }
}

.img3 {
  width: calc(100% + 32px);
  position: relative;
}
