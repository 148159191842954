@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  background: var(--fill5);
  padding: 32px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 424px;
  box-sizing: border-box;
  overflow: hidden;

  @include mediaTablet {
    width: 100%;
  }

  @include mediaMobile {
    width: 100%;
  }
}

.title {
  @include mediaMobile {
    font-size: 28px;
    line-height: 32px;
  }
}