//* screens less than 320px
@mixin mediaMobile {
  @media screen and #{$mobile-resolution} {
    @content;
  }
}

@mixin notMediaMobile {
  @media screen and #{$not-mobile-resolution} {
    @content;
  }
}

@mixin mediaTablet {
  @media screen and (max-width: 1000px) and (min-width: 577px) {
    @content;
  }
}

//* extra breakpoint
@mixin mediaExtra {
  @media screen and (max-width: 550px) {
    @content;
  }
}