$mobile-resolution: '(max-width: 576px)';
$not-mobile-resolution: '(min-width: 577px)';
$tablet-resolution: '(max-width: 1000px)';

// app measures
$max-app-width: 1440px;
$min-app-width: 320px;

// with PADDINGS and dox-sizing: border-box
$header-width: 1360px; // header container
$content-width: 1190px; // main container

$-webkit-font-smoothing: antialiased;

// text colors
$title-dark-color: #170925;
$text-white-color: #ffffff;
$text-dark-color: #170925;
$text-intro-color: #8b8492;
$text-basic-blocks-color: #5b5166;
$text-black-color: #000000;
$text-footer-light-color: #5d5366;
// $text-button-white: #2b1246;
$text-input-placeholder-color: #564d62;
// $text-step-color: #150a24;
$text-button-white-color: #ffffff;
$text-attention-color: #e75461;
$text-button-disabled: #898191;

// background colors
$main-bg: #fbf8fe;
$footer-bg: #150a24;
$challenge-bg: #2a1245;
$social-features-bg: #ffffff;

$button-purple-bg: #2b1246;
$button-white-bg: #ffffff;
$button-grey-bg: #e7e7e7;
$button-corral-bg: #e75461;
$button-gradient-orange-bg: linear-gradient(270deg, #fb7765 0.19%, #ffbd60 105.9%);
$cross-button-bg: #eae4ef;
$cross-button-line: #000000;

$font: 'Inter', 'Arial', sans-serif;

// animations
$basicTransitionLinear: 0.3s linear;
$basicTransformScale: scale(1.1);
$basicOpacityHover: 0.7;

$basicOpacity: 0.7;
