@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.card {
  background: var(--fill5);
  padding: 64px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;

  @include mediaMobile {
    padding: 32px;
  }
}