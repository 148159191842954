@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.text {
  text-decoration: none !important;
  margin: 0;
  color: var(--text0);
}

.h1 {
  font-size: var(--heading1);
  font-weight: 600;
  line-height: calc(var(--heading1) + 4px);
}

.h2 {
  font-size: var(--heading2);
  font-weight: 600;
  line-height: calc(var(--heading2) + 4px);


  @include mediaMobile {
    font-size: var(--heading3);
    line-height: calc(var(--heading3) + 4px);
  }
}

.h3 {
  font-size: var(--heading3);
  font-weight: 600;
  line-height: calc(var(--heading3) + 4px);

  @include mediaMobile {
    font-size: var(--heading2);
    line-height: calc(var(--heading2) + 4px);
  }
}

.h4 {
  font-size: var(--heading4);
  line-height: calc(var(--heading4) + 4px);
  font-weight: 600;
}

.pLargeSemibold {
  font-size: var(--pLargeSemibold);
  font-weight: 600;
  line-height: calc(var(--pLargeSemibold) + 8px);
}

.pLargeRegular {
  font-size: var(--pLargeRegular);
  font-weight: 400;
  line-height: calc(var(--pLargeRegular) + 8px);
}

.pMediumSemibold {
  font-size: var(--pMediumSemibold);
  font-weight: 600;
  line-height: calc(var(--pMediumSemibold) + 8px);
}

.pMediumRegular {
  font-size: var(--pMediumRegular);
  font-weight: 400;
  line-height: calc(var(--pMediumRegular) + 8px);
}

.pSmallSemibold {
  font-size: var(--pSmallSemibold);
  font-weight: 600;
  line-height: calc(var(--pSmallSemibold) + 6px);
}

.pSmallRegular {
  font-size: var(--pSmallRegular);
  font-weight: 400;
  line-height: calc(var(--pSmallRegular) + 6px);
}

.captionSemibold {
  font-size: var(--captionSemibold);
  font-weight: 600;
  line-height: calc(var(--captionSemibold) + 4px);
}

.captionRegular {
  font-size: var(--captionRegular);
  font-weight: 400;
  line-height: calc(var(--captionRegular) + 4px);
}

