@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.root {
  padding: 184px 0 112px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.title {
  font-size: 56px;
  line-height: 60px;

  @include mediaMobile {
    font-size: 40px;
    line-height: 48px;
  }
}