@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  background-color: rgba(#000, $alpha: 0.4);
  opacity: 0;
  visibility: hidden;
  transition: $basicTransitionLinear;
  z-index: 100;
}

.popupContainer {
  display: flex;
  width: 100%;
  border-radius: 32px;
  background: var(--fill6);
  box-sizing: border-box;
  position: relative;
  justify-content: center;
  align-items: center;
  transform: scale(2);
  opacity: 0;
  transition: 0.3s ease-out;

  &Dark {
    border: 1px solid var(--fill4);
  }
}

.popupOpened {
  display: flex;
  opacity: 1;
  visibility: visible;
}

.popup.popupOpened .popupContainer {
  transform: scale(1);
  opacity: 1;
}

.closeButton {
  padding: 0 !important;
  position: absolute !important;
  top: 24px;
  right: 24px;
}
