@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mediaMobile {
    padding: 48px 0;
  }
}
.title {
  font-size: 40px;
  line-height: 48px;

  @include mediaMobile {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
}

.row {
  display: flex;

  @include mediaMobile {
    flex-direction: column;
  }
}

.button {
  color: var(--text1);
  height: 48px;
}

.buttons {
  display: flex;
  gap: 12px;
}