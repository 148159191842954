@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.footer {
  position: relative;
  padding: 96px 0;

  @include mediaTablet {
    padding: 48px 0;
  }

  @include mediaMobile {
    padding: 48px 0;
  }
}

.card {
  border-radius: 24px;
  overflow: hidden;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;

  @include mediaTablet {
    width: calc(100% - 32px);
  }

  @include mediaMobile {
    width: calc(100% - 32px);
  }
}

.linkContainer {
  margin-right: 24px;

  @include mediaMobile {
    margin-right: 0;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.nav {
  display: flex;

  &Text {
    color: var(--text2);
  }

  @include mediaMobile {
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 32px;
  }
}

.textBlock {
  padding: 32px;
  display: flex;
  flex-direction: column;
  background: var(--fill5);

  &Text {
    color: var(--text2);
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  width: 100%;
  background: var(--fill5);
  box-sizing: border-box;

  @include mediaMobile {
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
  }
}

.icons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.chf {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 16px;
}