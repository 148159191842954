@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.card {
  background: var(--fill5);
  border-radius: 24px;
  padding: 64px 64px 64px 64px;
  display: flex;
  align-items: center;
  box-sizing: content-box;

  @include mediaTablet {
    padding: 32px;
  }

  @include mediaMobile {
    flex-direction: column;
    padding: 32px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  @include mediaMobile {
    width: 100%;
  }
}

.photo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 64px;
  height: 384px;
  width: 384px;
  @include mediaTablet {
    margin: 0 auto;
    padding: 0 16px;
  }

  @include mediaMobile {
    margin: 0 auto;
    width: 100%;
    padding: 0;
    height: unset;
  }
}

.name {
  color: var(--text2);
}