@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';

.button {
  padding: 8px 20px;
  border-radius: 12px;
  font-size: var(--pMediumSemibold);
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
  border: none;
  outline: none;
  background: var(--fill0);
  text-decoration: none !important;
  color: var(--fill1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    transition: 200ms;
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.64;
  }
  
  &Disabled {
    opacity: 0.4;
  }
}

.accentTheme {
  background: var(--primary);

  &:hover {
    background: var(--primaryDarker);
  }

  &:focus {
    background: var(--primaryDarker);
  }

  &Disabled {
    opacity: 0.4;
    background: var(--primary);
  }
}

.whiteTheme {
  background: var(--white);
  color: var(--text1);

  &:hover {
    background: var(--white);
  }

  &:focus {
    background: var(--white);
  }
}

.secondaryTheme {
  background: var(--secondary);
  color: #fff;

  &:hover {
    background: var(--secondary);
  }

  &:focus {
    background: var(--secondary);
  }
}

.greyTheme {
  background: var(--fill3);
  color: #fff;

  &:hover {
    background: var(--fill3);
  }

  &:focus {
    background: var(--fill3);
  }
}

.iconType {
  background: none;

  &:hover {
    background: none;
  }

  &:focus {
    background: none;
  }
}