@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.card {
  background: var(--fill5);
  padding: 64px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 64px;

  @include mediaMobile {
    padding: 32px;
    margin-top: 32px;
    align-items: center;
  }
}

.members {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include mediaMobile {
    gap: unset
  }
}

.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  margin-bottom: 36px;

  @include mediaMobile {
    width: 50%;
  }

  &__img {
    width: 160px;
    height: 160px;
    border-radius: 50%;

    @include mediaMobile {
      width: 96px;
      height: 96px;
    }
  }

  .info {
    display: flex;
    flex-wrap: wrap;
  }
}

.position {
  color: var(--text2);

  @include mediaMobile {
    display: none;
  }

  &__mobile {
    display: none;
    color: var(--text2);

    @include mediaMobile {
      display: unset;
    }
  }
}

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
}

.linkedin {
  width: 20px;
  height: 20px;
}