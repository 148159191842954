.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 112px 0;
}

.card {
  width: 100%;
}

.texts {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 70%;
}