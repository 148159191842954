@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.header {
  padding: 20px 32px;
  width: calc(100%);
  box-sizing: border-box;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  display: flex;

  &Transparent {
    background: rgba($color: var(--fill6), $alpha: 1.0);
    backdrop-filter: blur(10px);
  }

  @include mediaMobile {
    padding: 16px;
  }
}

.navbar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  position: relative;
}

.logoContainer {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  img {
    height: 30px;
  }
}

.navigationContainer {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.actionsContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.careers {
  display: flex;
  align-items: center;

  @include mediaMobile {
    display: none;
  }
}

.burgerContainer {
  position: relative;
  @include notMediaMobile {
    display: none;
  }
}

.burgerButton {
  padding: 5px;
}

.burgerButtonLine {
  margin-bottom: 5px;
  width: 16px;
  height: 2px;
  background-color: #000000;
  transition: 0.4s linear;

  &:last-of-type {
    margin: 0;
  }
}

.burgerIcon {
  width: 24px;
  height: 24px;
}

.burgerMenu {
  top: 60px;
  right: 16px;
  width: 170px;
  border-radius: 12px;
  overflow: hidden;
  gap: 1px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: var(--fill4);

  &Item {
    padding: 14px 16px;
    background: var(--fill6);
  }

  &__dark {
    border: 1px solid var(--fill4);
  }
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba($color: #000, $alpha: 0.6);
  top: 0;
  left: 0;
}

.themeButton {
  padding: 4px;
}

.popupButton {
  color: var(--text1)
}