@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 200ms;
  margin: 64px 0;

  @include mediaMobile {
    margin: 48px 0;
    opacity: 1;
  }
}

.card {
  background: var(--fill5);
  border-radius: 24px;
  padding: 62px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @include mediaMobile {
    padding: 32px;
    flex-direction: column;
  }
}

.leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: auto;
  width: 432px;

  @include mediaTablet{
    width: 100%;
  }

  @include mediaMobile {
    width: 100%;
  }
}

.img {
  height: 100%;
  margin-left: 64px;
  @include mediaTablet{
    display: none;
  }
  @include mediaMobile {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-top: 32px;
  }
}

.point {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  &Text {
    color: var(--text2);
    width: 336px;
    @include mediaTablet{
      width: 100%;
    }
    @include mediaMobile {
      width: 100%;
    }
  }

  &LeftColumn {
    display: flex;
    flex-direction: column;
    width: calc(100% - 98px);
    @include mediaTablet{
      width: calc(100% - 98px);
    }
    @include mediaMobile {
      width: calc(100% - 98px);
    }
  }

  &img {
    border-radius: 50%;
    background: var(--primary);
    width: 64px;
    height: 64px;
    margin-left: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: unset;
      height: unset;
    }

  }
}