@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.page {
  position: relative;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.main {
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;

  @include mediaTablet {
    padding: 0 16px;
  }

  @include mediaMobile {
    padding: 0 16px;
  }
}

.ppVersion {
  max-width: unset;
  
  @include mediaMobile {
    padding: 0;
  }

  @include mediaTablet {
    padding: 0
  }
}

