@import './colors.scss';
@import './text.scss';
@import './variables.scss';
@import './mixins.scss';
@import './fonts.scss';

:root {
  --primary: #E75461;
  --primaryDarker: #BD4653;
  --secondary: #51AAA5;
  --white: #FFFFFF;
  --black: #140D1C;
  --error: #E75461;
  --positive: #51AAA5;
  --masterCard: #D2CAC3;
  --visa: #D2D2D2;

  --heading1: 32px;
  --heading2: 28px;
  --heading3: 24px;
  --heading4: 20px;

  --pLargeSemibold: 20px;
  --pLargeRegular: 20px;
  --pMediumSemibold: 16px;
  --pMediumRegular: 16px;
  --pSmallSemibold: 14px;
  --pSmallRegular: 14px;
  --captionSemibold: 12px;
  --captionRegular: 12px;
}

html {
  height: 100%;
  // scroll-behavior: smooth;
}


body {
  background-color: var(--fill6);
  min-height: 100vh;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: normal;
  color: $black;
  -webkit-font-smoothing: $-webkit-font-smoothing;
  /* disabling ios automatic font-size adjustment */
  -webkit-text-size-adjust: 100%;
  transition: background-color 400ms ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;


  &::-webkit-scrollbar {
    width: 10px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(23, 9, 37, 0.6);
    border-radius: 10px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
  height: auto;
  user-select: none;
}