@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.ddWrapper {
  button {
    overflow: visible;
    width: inherit;
    margin: inherit;
    padding: inherit;
    border: none;
    background: inherit;
    font: inherit;
    line-height: normal;
    color: inherit;
    text-align: inherit;
    -webkit-appearance: none;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  p {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
}

.ddWrapper {
  position: relative;
  user-select: none;

  // @include mediaTablet {
  //   width: 45px;
  // }

  .ddHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 3px;
    background-color: transparent;
    cursor: default;
    cursor: pointer;

    span {
      display: flex;
      margin-right: 20px;
    }

    .ddHeaderTitle {
      font-family: $font;
      font-style: normal;
      font-weight: 500;
      font-size: $pMediumSemibold;
      // line-height: 2;
      // margin: 2px 20px;
      margin-right: 5px;
      // font-weight: 300;

      // @include mediaTablet {
      //   font-size: $size-xs;
      //   font-weight: $font-weight-normal;
      // }
    }
  }

  .angleDown {
    margin-right: 20px;
    color: black;
  }

  .ddList {
    // position: absolute;
    z-index: 10;
    right: 0;
    top: 40px;
    // width: 100%;
    // border: 1px solid rgb(223, 223, 223);
    // border-top: none;
    // border-bottom-left-radius: 3px;
    // border-bottom-right-radius: 3px;
    // box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    background-color: white;
    font-weight: 500;
    text-align: left;
    -webkit-overflow-scrolling: touch;

    position: absolute;
    width: 158px;
    // min-height: 160px;
    max-height: 215px;

    // left: 1250px;
    // top: 82px;

    /* light/elementsBackground */

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    @include mediaTablet {
      left: -80px;
      top: 40px;
      width: 125px;
    }

    .ddScrollList {
      overflow: hidden; // TODO !!!
      // overflow-y: scroll;
      max-height: 215px;
      padding: 11px 0;

      .ddListItem {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        padding: 11px;

        font-family: $font;
        font-style: normal;
        // font-weight: $font-weight-normal;
        // font-size: $size-xxxxs;
        line-height: 1.42;
        text-align: center;

        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: default;
        cursor: pointer;

        &.NoResult {
          font-weight: normal;
          cursor: default;

          &:hover {
            background-color: transparent;
            color: black;
          }
        }

        &:hover {
          // text-decoration: underline;
          // background-color: var(--fill3);
          font-weight: 600;
          // color: white;

          & > span > svg > path {
            fill: white;
          }
        }
      }

      .ddListSearchBar {
        width: 100%;
        height: 40px;
        padding: 0 10px;
        border: none;
        border-bottom: 1px solid #dfdfdf;
        font-size: inherit;

        &::placeholder {
          color: rgb(200, 200, 200);
        }

        &.Searchable {
          overflow-y: hidden;
          padding: 0;
        }

        .ddScrollList {
          max-height: calc(215px - 40px);
          padding: 10px 0;
        }
      }
    }
  }
}

.currentItemMarker {
  // color: red;
  font-weight: 500 !important;
  font-size: $pMediumSemibold;
  pointer-events: none;
  cursor: default;
}

@include mediaTablet {
  .ddList {
    left: 80px;
    top: -20px;
  }
}
