@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Black.ttf');
  src: local('Inter Black'), local('Inter-Black'),
    url('./fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-ExtraBold.ttf');
  src: local('Extra Bold'), local('ExtraBold'),
    url('./fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Bold.ttf');
  src: local('Inter Bold'), local('Inter-Bold'),
    url('./fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-SemiBold.ttf');
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
    url('./fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Medium.ttf');
  src: local('Inter Medium'), local('Inter-Medium'),
    url('./fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.ttf');
  src: local('Inter Regular'), local('Inter-Regular'),
    url('./fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Light.ttf');
  src: local('Inter Light'), local('Inter-Light'),
    url('./fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-ExtraLight.ttf');
  src: local('Inter ExtraLight'), local('Inter-ExtraLight'),
    url('./fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Thin.ttf');
  src: local('Inter Thin'), local('Inter-Thin'),
    url('./fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}