@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.closeButton {
  display: inline-block;
  width: 32px;
  height: 32px;

  background-color: var(--fill5);
  outline: none;
  border: none;
  padding: 0;
  transition: 0.3s ease;

  border-radius: 50%;

  position: relative;

  &:hover {
    cursor: pointer;
    transform: $basicTransformScale;
  }
}

.closeButton {
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    background: url('../../../images/cross-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &Dark::after {
    background: url('../../../images/cross-icon-dark.svg');
    content: '';
    position: absolute;
    z-index: 1;
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
  }
}