$heading1: 32px;
$heading2: 28px;
$heading3: 24px;
$heading4: 20px;

$pLargeSemibold: 20px;
$pLargeRegular: 20px;
$pMediumSemibold: 16px;
$pMediumRegular: 16px;
$pSmallSemibold: 14px;
$pSmallRegular: 14px;
$captionSemibold: 12px;
$captionRegular: 12px;