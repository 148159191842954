@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  padding: 112px 0;

  @include mediaMobile {
    padding: 0 0 112px;
  }
}

.cards {
  display: flex;
  width: 100%;
  justify-content: stretch;
  gap: 16px;

  @include mediaMobile {
    flex-direction: column;
  }
}

.card {
  background: var(--fill5);
  border-radius: 24px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: calc((100% / 3) - 16px);
  box-sizing: border-box;

  &__disabled {
    opacity: 0.5;
  }

  @include mediaMobile {
    max-width: 100%;
  }
}

.link {
  display: flex;
  gap: 15px;
  align-items: center;
}