@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  display: flex;
  flex-direction: column;
  padding: 192px 0;
  width: 55%;

  @include mediaMobile {
    padding: 136px 0;
    width: 100%;
  }
}

.title {
  font-size: 56px !important;
  line-height: 64px !important;

  @include mediaMobile {
    font-size: 40px !important;
    line-height: 48px !important;
  }
}

.description {
  color: var(--text2) !important;
}