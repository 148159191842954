@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.root {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--secondary);
  border-radius: 24px;
  padding: 24px;
  display: flex;
  max-width: 1216px;
  width: calc(100vw - 16px);
  align-items: center;
  box-sizing: border-box;
  gap: 16px;

  @include mediaMobile {
    flex-direction: column;
    padding: 42px 24px 24px;
  }
}

.buttons {
  display: flex;
  height: fit-content;
  gap: 8px;
  @include mediaMobile {
    width: 100%;
  }

  &__button {
    @include mediaMobile {
      width: 100%;
    }
  }
}

.text {
  color: var(--white);
}

.buttonText {
  white-space: nowrap;
  color: var(--black);
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;

  @include mediaMobile {
    top: 10px;
  }
}

.settingsModal {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-sizing: border-box;
  align-items: flex-start;

  @include mediaMobile {
    width: calc(100% - 16px);
  }
}

.pseudoCheckboxLabel {
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
}

/* Hide the browser's default checkbox */
.pseudoCheckboxInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox from span */
.pseudoCheckbox {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: 2px solid var(--fill3);
  box-sizing: border-box;
}

/* On mouse-over, add a grey background color */
.pseudoCheckboxLabel:hover .pseudoCheckboxInput ~ .pseudoCheckbox {
  border: 2px solid #e75461;
}

/* When the checkbox is checked, add a blue background */
.pseudoCheckboxLabel .pseudoCheckboxInput:checked ~ .pseudoCheckbox {
  background-color: var(--primary);
  border: none;
}

.pseudoCheckboxLabel .pseudoCheckboxInput:checked ~ .pseudoCheckbox::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 18px;
  height: 16px;
  transform: translate(-50%, -50%);
  background-image: url('../../../images/tick-icon-white.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.pseudoCheckboxTexts {
  display: flex;
  flex-direction: column;
}

.pseudoCheckboxTitle {
  display: block;
  margin-left: 32px;
}

.pseudoCheckboxText {
  display: block;
  margin-left: 32px;
  color: var(--text2);
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0;
}

.settingsButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.button {
  height: 48px;
}