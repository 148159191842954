.root {
  padding: 112px 0;
}

.title {
  font-size: 56px;
  line-height: 64px;
}

.text {
  color: var(--text2);
}