@import '../../../styles/colors.scss';
@import '../../../styles/text.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  display: flex;
  padding: 72px 0;
  height: calc(100vh);
  box-sizing: border-box;
  align-items: center;
  @include mediaMobile {
    flex-direction: column;
    box-sizing: unset;
    padding: 48px 0 22px;
    margin-top: 80px;
    height: auto;
  }
}

.leftColumn {
  width: 50%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mediaMobile {
    width: 100%;
  }
}

.title {
  font-size: 56px;
  line-height: 64px;
  animation-name: title;
  animation-duration: 1.4s;
  position: relative;

  @include mediaMobile {
    font-size: 40px;
    line-height: 48px;
    animation-name: none;
  }

  @include mediaTablet {
    font-size: 40px;
    line-height: 48px;
  }
}

.text {
  color: var(--text2);
  animation-name: title;
  animation-duration: 1.6s;
  position: relative;

  @include mediaMobile {
    animation-name: none;
  }
}

.img {
  display: flex;
  height: 458px;
  width: 50%;
  align-items: center;
  justify-content: flex-end;
  animation-name: title;
  animation-duration: 1.4s;
  position: relative;

  img {
    width: auto;
    height: 100%;
  }

  @include mediaMobile {
    width: 90%;
    margin: 40px auto 0;
    flex-direction: column;
    animation-name: none;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.button {
  padding: 12px 32px;
  animation-name: title;
  animation-duration: 1.8s;
  position: relative;
  color: var(--text1);

  @include mediaMobile {
    animation-name: none;
  }
}

.buttons {
  display: flex;
  gap: 12px;
  position: relative;

  animation-name: title;
  animation-duration: 1.8s;
}

@keyframes title {
  from {
    opacity: 0;
    top: 20px;
  }

  30% {
    opacity: 0;
    top: 20px;
  }

  to {
    opacity: 1;
    top: 0px;
  }
}


